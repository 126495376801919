import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'

import App from 'containers/App'
import correctDomain from 'config/correctDomain'
import checkBrowserFeatures from 'config/checkBrowserFeatures'
import setThemeFromStorage from 'config/setThemeFromStorage'

import 'style'

// setup

correctDomain()
checkBrowserFeatures()
setThemeFromStorage()

const MOUNT_NODE = document.getElementById('root')

// startup

ReactDOM.render(<App />, MOUNT_NODE)
