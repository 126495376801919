import React from 'react'
import theme from 'style/theme'

export const LINK_TYPES = {
  invitation: 'RETRO_INVITATION',
}

export const AUTHOR_LINK = 'https://gus.siteless.co'

export const VERSION_HASH = process.env.REACT_APP_GIT_COMMIT_HASH

export const PRODUCTION_HOST = 'hindsight.siteless.co'

export const IS_PRODUCTION =
  process.env.NODE_ENV === 'production' &&
  !process.env.REACT_APP_PRODUCTION_TRIP

export const RETRO_PATH = '/retro'

export const RETRO_PHASES = {
  stage: {
    key: 'stage',
    displayName: 'Staging',
    description: 'Retro is in a pending state so new members can join.',
    isVisibleToTracker: false,
  },
  collect: {
    key: 'collect',
    displayName: 'Collect',
    advanceToMessage: 'Begin Retro',
    isVisibleToTracker: true,
    description: 'Members add cards to the retro.',
  },
  review: {
    key: 'review',
    displayName: 'Review',
    isVisibleToTracker: true,
    description: 'Members review and cull cards.',
  },
  vote: {
    key: 'vote',
    displayName: 'Vote',
    isVisibleToTracker: true,
    description: "Members vote on cards they'd like to discuss.",
  },
  resolve: {
    key: 'resolve',
    displayName: 'Resolve',
    isVisibleToTracker: true,
    description: 'Members discuss marked cards and create action items.',
  },
  complete: {
    key: 'complete',
    displayName: 'Complete',
    advanceToMessage: 'Complete Retro',
    isVisibleToTracker: false,
    description: 'Retro has been completed.',
  },
}

export const CONFIG_VERSION = 'v1'

export const COLUMN_NAME_MAP_REVERSE = {
  positive: 0,
  neutral: 1,
  negative: 2,
}

export const COLUMN_MESSAGE_MAP = {
  0: (
    <>
      <div>No positive cards.</div>
      {/* eslint-disable-next-line */}
      <div>Yet 🤩</div>
    </>
  ),
  1: (
    <>
      <div>No neutral cards.</div>
      <div>Huh.</div>
    </>
  ),
  2: (
    <>
      <div>No negative cards.</div>
      <div>That's a problem...</div>
    </>
  ),
}

export const COLUMN_ICON_MAP = {
  0: ({ className }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8ZM15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8ZM8.55 14C9.25 15.19 10.52 16 12 16C13.48 16 14.75 15.19 15.45 14H17.12C16.32 16.05 14.33 17.5 12 17.5C9.67 17.5 7.68 16.05 6.88 14H8.55ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
        fill={theme.positiveColor}
      />
    </svg>
  ),
  1: ({ className }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM7 9.5C7 8.67157 7.67157 8 8.5 8C9.32843 8 10 8.67157 10 9.5C10 10.3284 9.32843 11 8.5 11C7.67157 11 7 10.3284 7 9.5ZM15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8ZM9 15.5V14H15V15.5H9ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
        fill={theme.neutralColor}
      />
    </svg>
  ),
  2: ({ className }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8ZM15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8ZM6.88 17.5C7.68 15.45 9.67 14 12 14C14.33 14 16.32 15.45 17.12 17.5H15.45C14.75 16.31 13.48 15.5 12 15.5C10.52 15.5 9.24 16.31 8.55 17.5H6.88ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
        fill={theme.negativeColor}
      />
    </svg>
  ),
}

export const MERGE_ACTION = 'MERGE'

export const MOVE_ACTION = 'MOVE'

export const EDIT_ACTION = 'EDIT'
