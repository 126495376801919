import { css } from 'linaria'
import { THEME_DATA_TARGET, THEME_DATA_OPTIONS } from 'config/theme'

css`
  :global() {
    html,
    html[${THEME_DATA_TARGET}='${THEME_DATA_OPTIONS.dark}'] {
      --neutral0: #ffffff;
      --neutral100: #f2f2f2;
      --neutral200: #eeeeee;
      --neutral300: #e0e0e0;
      --neutral400: #bdbdbd;
      --neutral500: #8a8a8a;
      --neutral600: #757575;
      --neutral700: #616161;
      --neutral800: #424242;
      --neutral900: #232323;
      
      --actionColor: #8f7cff;
      --positiveColor: #59DE96;
      
    }
    
    html[${THEME_DATA_TARGET}='${THEME_DATA_OPTIONS.light}'] {
      --neutral0: #232323;
      --neutral100: #3e3e3e;
      --neutral200: #424242;
      --neutral300: #555555;
      --neutral400: #616161;
      --neutral500: #929292;
      --neutral600: #aeaeae;
      --neutral700: #b5b5b5;
      --neutral800: #c7c7c7;
      --neutral900: #ffffff;

      --actionColor: #8370f7;
      --positiveColor: #25ce70;
    }
  }
`

export default {
  neutral0: 'var(--neutral0)',
  neutral100: 'var(--neutral100)',
  neutral200: 'var(--neutral200)',
  neutral300: 'var(--neutral300)',
  neutral400: 'var(--neutral400)',
  neutral500: 'var(--neutral500)',
  neutral600: 'var(--neutral600)',
  neutral700: 'var(--neutral700)',
  neutral800: 'var(--neutral800)',
  neutral900: 'var(--neutral900)',

  background: 'var(--neutral900)',

  brightText: 'var(--neutral200)',
  text: 'var(--neutral400)',
  fadedText: 'var(--neutral500)',

  headerFont:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  bodyFont:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',

  neutralColor: '#F4B24E',
  positiveColor: 'var(--positiveColor)',
  negativeColor: '#E1476C',

  actionColor: 'var(--actionColor)',
  fadedActionColor: '#8f83d8',

  borderRadius: '4px',
  border: `1px solid var(--neutral500)`,
}
