import React from 'react'
import useCollection from 'hooks/useCollection'
import useUser from 'hooks/useUser'
import Action from 'components/Action'
import Loader from 'components/Loader'

import rootClassName from './RequestingEntryMembers.style'
import UserImage from '../UserImage/UserImage'
import Tooltip from '../Tooltip/Tooltip'
import { makeClassName } from '@scriptless/util'
import uniq from 'lodash/uniq'
import {
  admitRequestingEntryUser,
  removeRequestingEntryUser,
} from 'services/retro'

const RequestingEntryMembers = ({ className, retro }) => {
  const { requestingEntryUserIds =[] } = retro
  const user = useUser()
  const users = useCollection('users')

  const userList = React.useMemo(() => {
    if (!(user && users)) return null

    return uniq(requestingEntryUserIds).map((id) => ({
      id,
      ...users[id],
    }))
  }, [user, users, requestingEntryUserIds])

  if (!userList) return <Loader />

  return (
    <div className={makeClassName(rootClassName.base, className)}>
      {userList.map(({ id, handle, photoUrl }) => (
        <Tooltip
          className="__tooltip"
          key={id}
          content={
            <div className={rootClassName.tooltip}>
              <div>{handle} is requesting entry.</div>
              <Action
                onClick={() => {
                  admitRequestingEntryUser({ retroId: retro.id, userId: id })
                }}
              >
                Admit
              </Action>
              <Action
                onClick={() => {
                  removeRequestingEntryUser({ retroId: retro.id, userId: id })
                }}
              >
                Remove
              </Action>
            </div>
          }
        >
          <UserImage size={24} src={photoUrl} faded />
        </Tooltip>
      ))}
    </div>
  )
}

export default RequestingEntryMembers
