import React from 'react'
import HomePage from 'containers/HomePage'
import RetroPage from 'containers/RetroPage'
import LinkPage from 'containers/LinkPage'
import AccountPage from 'containers/AccountPage'
import privacyPolicyCopy from 'assets/privacy-policy.md'
import termsOfUseCopy from 'assets/terms-of-use.md'
import CopyPage from 'components/CopyPage'
import { RETRO_PATH } from './constants'

const routes = [
  {
    isPrivate: true,
    path: '/',
    exact: true,
    Component: HomePage,
  },
  {
    isPrivate: true,
    path: '/account-settings',
    exact: true,
    Component: AccountPage,
  },
  {
    path: '/privacy-policy',
    exact: true,
    Component: () => <CopyPage copy={privacyPolicyCopy} />,
  },
  {
    path: '/terms-of-use',
    exact: true,
    Component: () => <CopyPage copy={termsOfUseCopy} />,
  },
  {
    isPrivate: true,
    path: RETRO_PATH + '/:retroId',
    exact: true,
    Component: RetroPage,
  },
  {
    isPrivate: true,
    path: '/link/:linkId',
    exact: true,
    Component: LinkPage,
  },
]

export default routes
